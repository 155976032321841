<template>
  <div style="margin: 10px 0px">
    <Loader v-if="showLoader" />
    <v-row v-else no-gutters justify="center">
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" style="margin-bottom: 50px">
        <div class="backgroundDrawer" style="margin: 10px;">
          <v-row no-gutters align="center" style="margin-bottom: 20px">
            <span class="drawerTitle">Деталі купівлі</span>
          </v-row>
          <v-row
            no-gutters
            align="center"
            style="
              border-bottom: 1px solid #f4f4f4;
              padding: 8px 0px;
              margin-top: 20px;
            "
          >
            <v-col cols="4" class="px-0 py-0">Квиток</v-col>
            <v-col cols="8" class="py-0" style="padding-left: 20px"
              >{{ ticket?.departure?.translations?.name }}
              -
              {{ ticket?.destination?.translations?.name }}</v-col
            >
          </v-row>
          <v-row
            no-gutters
            align="center"
            style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
          >
            <v-col cols="4" class="px-0 py-0">Дата купівлі</v-col>
            <v-col cols="8" class="py-0" style="padding-left: 20px"
              >{{
                new Date(ticket.created_at).toLocaleDateString("uk-UA", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              }}
              {{
                new Date(ticket.created_at).toLocaleTimeString("uk-UA", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}</v-col
            >
          </v-row>
          <v-row
            no-gutters
            align="center"
            style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
          >
            <v-col cols="4" class="px-0 py-0">Відправлення</v-col>
            <v-col cols="8" class="py-0" style="padding-left: 20px"
              >{{
                new Date(ticket?.schedule?.departed_at).toLocaleDateString(
                  "uk-UA",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                )
              }}
              {{
                new Date(ticket?.schedule?.departed_at).toLocaleTimeString(
                  "uk-UA",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )
              }}</v-col
            >
          </v-row>
          <v-row
            no-gutters
            align="center"
            style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
          >
            <v-col cols="4" class="px-0 py-0">Прибуття</v-col>
            <v-col cols="8" class="py-0" style="padding-left: 20px"
              >{{
                new Date(ticket?.schedule?.arrival_at).toLocaleDateString(
                  "uk-UA",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                )
              }}
              {{
                new Date(ticket?.schedule?.arrival_at).toLocaleTimeString(
                  "uk-UA",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )
              }}</v-col
            >
          </v-row>
          <v-row
            no-gutters
            align="center"
            style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
          >
            <v-col cols="4" class="px-0 py-0">Кількість квитків</v-col>
            <v-col cols="8" class="py-0" style="padding-left: 20px">{{
              ticket.seats_count
            }}</v-col>
          </v-row>
          <v-row
            no-gutters
            align="center"
            style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
          >
            <v-col cols="4" class="px-0 py-0">Місце</v-col>
            <v-col cols="8" class="py-0" style="padding-left: 20px"
              >Вільне</v-col
            >
          </v-row>
          <v-row
            no-gutters
            align="center"
            style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
          >
            <v-col cols="4" class="px-0 py-0">Вартість</v-col>
            <v-col cols="8" class="py-0" style="padding-left: 20px"
              >{{ ticket.final_price }} грн</v-col
            >
          </v-row>
          <v-row
            no-gutters
            align="center"
            style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
          >
            <v-col cols="4" class="px-0 py-0">Списано бонусів</v-col>
            <v-col cols="8" class="py-0" style="padding-left: 20px">{{
              ticket.bonus
            }}</v-col>
          </v-row>
          <v-row
            no-gutters
            align="center"
            style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
          >
            <v-col cols="4" class="px-0 py-0">Статус</v-col>
            <v-col cols="8" class="py-0" style="padding-left: 20px"
              ><span
                :style="
                  ticket.status == 'Payed'
                    ? 'color: #149E51'
                    : ticket.status == 'Canceled'
                    ? 'color: #DF3A3A'
                    : 'color:#E19700'
                "
              >
                {{
                  ticket.status == "Payed"
                    ? "Оплачено"
                    : ticket.status == "Canceled"
                    ? "Скасовано"
                    : "Заброньовано"
                }}
              </span></v-col
            >
          </v-row>
          <v-col
            class="px-0 py-0"
            v-for="(ticket, index) in ticket.tickets"
            :key="ticket.ud"
          >
            <p class="drawerSubtitle">Пасажир {{ index + 1 }}</p>
            <v-row
              no-gutters
              align="center"
              style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
            >
              <v-col cols="4" class="px-0 py-0">ПІБ</v-col>
              <v-col cols="8" class="py-0" style="padding-left: 20px"
                >{{ ticket.first_name }} {{ ticket.last_name }}</v-col
              >
            </v-row>
            <v-row
              no-gutters
              align="center"
              style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
            >
              <v-col cols="4" class="px-0 py-0">Тип квитка</v-col>
              <v-col cols="8" class="py-0" style="padding-left: 20px"
                >Дорослий</v-col
              >
            </v-row>
            <v-row
              no-gutters
              align="center"
              style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
            >
              <v-col cols="4" class="px-0 py-0">Email</v-col>
              <v-col cols="8" class="py-0" style="padding-left: 20px">{{
                ticket.email
              }}</v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
            >
              <v-col cols="4" class="px-0 py-0">Телефон</v-col>
              <v-col cols="8" class="py-0" style="padding-left: 20px">{{
                ticket.phone_number
              }}</v-col>
            </v-row>
          </v-col>

          <v-row
            class="px-0 py-0"
            style="margin-top: 40px"
            v-if="ticket.status == 'Payed' && !$vuetify.breakpoint.smAndDown"
          >
            <submit-button
              @click="downloadTickets(ticket.id)"
              style="
                width: calc(50% - 10px) !important;
                height: 48px !important;
                margin-right: 10px;
              "
              >Завантажити квиток</submit-button
            >
            <v-btn class="deleteBtn">Повернення коштів</v-btn>
          </v-row>
          <v-row
            class="px-0 py-0"
            style="margin-top: 40px"
            v-if="ticket.status == 'Payed' && $vuetify.breakpoint.smAndDown"
          >
            <submit-button
              @click="downloadTickets(ticket.id)"
              style="height: 48px !important"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'width: 100% !important;'
                  : 'width: calc(50% - 10px); margin-right: 10px;'
              "
              >Завантажити квиток</submit-button
            >
            <v-btn
              class="deleteBtn"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'width: 100% !important; margin-left: 0px;margin-top: 10px'
                  : ''
              "
              >Повернення коштів</v-btn
            >
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loader from "@/components/UI/Loader.vue";
import bookingTicketService from "../../requests/User/bookingTicketService";
//import VueQRCodeComponent from "vue-qrcode-component";
import diffrentTimeMixin from "@/mixins/diffrentTimeMixin";
import SubmitButton from "../../components/UI/Buttons/submitButton.vue";
export default {
  components: {
    //VueQRCodeComponent,
    Loader,
    SubmitButton,
  },
  mixins: [diffrentTimeMixin],
  data: () => ({
    ticket: {},
    price: 0,
    text: "",
    showLoader: true,
  }),
  mounted() {
    this.getOrderById();
  },
  methods: {
    async getOrderById() {
      await bookingTicketService
        .getOrderByQr(this.$route.params.order_id)
        .then((res) => {
          this.ticket = res.data;
          this.price = this.ticket.tickets.reduce(
            (sum, item) => sum + item.price,
            0
          );
          this.setTripTime();
          if (this.ticket.status !== "Canceled") {
            this.text = `${location.protocol}//${location.host}/order_qr/${this.ticket.uuid}`;
            this.showLoader = false;
          } else {
            this.text = "На жаль квиток вже не активний";
            this.showLoader = false;
          }
        });
    },
  },
};
</script>

<style scoped>
.backgroundDrawer {
  border-radius: 15px;
  background: #fff;
  padding: 20px 20px 30px 20px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.15);
}
.drawerSubtitle {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 0px;
}
.drawerTitle {
  font-size: 20px;
  font-weight: 700;
}
.deleteBtn {
  border-radius: 10px;
  background: rgba(223, 58, 58, 0.1) !important;
  height: 46px !important;
  width: calc(50% - 10px);
  margin-left: 10px;
  padding: 12px 16px;
  display: grid;
  place-items: center;
  font-size: 16px !important;
  font-weight: 400;
  color: #df3a3a !important;
  text-transform: none !important;
}
</style>